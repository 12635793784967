<template>
    <section aria-labelledby="payment-and-shipping-heading" class="py-8 md:py-16 lg:w-full lg:mx-auto lg:pt-0 lg:pb-24 lg:row-start-1 lg:col-start-1 lg:col-end-7">
        <h2 id="payment-and-shipping-heading" class="sr-only">Payment and shipping details</h2>

        <form v-on:submit.prevent>
          <div class="max-w-2xl mx-auto px-4 lg:max-w-none lg:px-0">
            <div>
              <h3 id="contact-info-heading" class="text-lg font-medium text-gray-900">Recapiti</h3>

              <div class="mt-6">
                <div class="mt-1">
                  <input type="email" id="email-address" placeholder="Email" v-model="user.email" @input="setUser('email', $event.target.value)" name="email-address" autocomplete="email" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.email }" />
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.email ">Questo campo è obbligatorio</span>
                </div>
              </div>
            </div>          

            <div class="mt-10">
              <h3 id="shipping-heading" class="text-lg font-medium text-gray-900">Dati personali</h3>

              <div class="mt-6 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6">
                
                <div class="sm:col-span-3">                  
                  <div class="mt-1">
                    <input type="text" id="nome" name="nome" :value="user.nome" @input="setUser('nome', $event.target.value)" placeholder="Nome" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.nome }" />
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.nome ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-3">                  
                  <div class="mt-1">
                    <input type="text" id="cognome" name="cognome" v-model="user.cognome" @input="setUser('cognome', $event.target.value)" placeholder="Cognome" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.cognome }" />
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.cognome ">Questo campo è obbligatorio</span>
                  </div>
                </div>
                
                <div class="sm:col-span-6">                  
                  <div class="mt-1">
                    <input type="text" id="indirizzo" name="indirizzo" v-model="user.indirizzo" @input="setUser('indirizzo', $event.target.value)" placeholder="Indirizzo e numero civico" autocomplete="street-address" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.indirizzo }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.indirizzo ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">                  
                  <div class="mt-1">
                    <input type="text" id="cap" name="cap" v-model="user.cap" placeholder="CAP" @input="setUser('cap', $event.target.value)" autocomplete="postal-code" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.cap }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.cap ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">                  
                  <div class="mt-1">
                    <input type="text" id="city" name="city" v-model="user.citta" @input="setUser('citta', $event.target.value)" placeholder="Città" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.citta }"/>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.citta ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-2">
                  <!-- <label for="provincia" class="block text-sm font-medium text-gray-700">Provincia</label> -->
                  <div class="mt-1">                    
                    <select id="provincia" name="provincia" v-model="user.provincia" @input="setUser('provincia', $event.target.value)" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" placeholder="Provincia" :class="{ 'border-2 border-red-600 focus:ring-0 focus:border-red-600' : errors.nome }">
                      <Province />
                    </select>
                    <span class="pt-2 text-red-600 text-sm" v-if="errors.provincia ">Questo campo è obbligatorio</span>
                  </div>
                </div>

                <div class="sm:col-span-6">                  
                  <div class="mt-1">
                    <input type="text" id="phone" name="phone" v-model="user.telefono" @input="setUser('telefono', $event.target.value)" placeholder="Numero di telefono (facoltativo)" autocomplete="phone" class="block w-full border-gray-300 rounded-md focus:ring-gray-900 focus:border-gray-900 sm:text-sm" />
                  </div>
                </div>
                
              </div>
            </div>            

            <div class="mt-10 pb-12">
              <button v-on:click="stepControl()" type="submit" class="button--big block w-full md:w-auto bg-yellow-400 bg-opacity-80 border border-transparent rounded-md text-sm font-medium text-gray-900 hover:bg-yellow-400 hover:bg-opacity-90 focus:outline-none">Vai al pagamento</button>
            </div>

            <BottomMenu />

          </div>
        </form>
    </section>
</template>

<script>
import Province from './Province.vue';
import BottomMenu from './BottomMenu.vue';
import { computed } from 'vue';
import { state, setUser } from './User';

const user = computed({
    get: () => state.user    
});

export default {  
    setup() {
        return {
            user, 
            setUser,            
        }
    },    
    data() {
        return {
            errors : {}
        }
    },
    methods: {
        stepControl: function(){              
            let hasErrors = false;      
            for (const [key, value] of Object.entries(state.user)) {
                !value && !['codicefiscale','pec','prodotto', 'prezzo'].includes(key) ? hasErrors = true : null;
                this.errors[key] = !value ? true : false;
            }                
            !hasErrors ? this.$router.push('step2') : null;    
        },        
    },
    mounted() {        
    },
    components: {
        Province,
        BottomMenu
    }
}
</script>
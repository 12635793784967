<template>
    <ul class="list-none pt-6 text-xs text-gray-600 border-t border-gray-200">
        <li class="float-left pr-4 hover:text-gray-800 pt-2 md:pt-0"><a href="https://corsiformazione.online/policies/refund-policy.html?locale=it" target="_blank">Informativa sui rimborsi</a></li>
        <li class="float-left pr-4 hover:text-gray-800 pt-2 md:pt-0"><a href="https://corsiformazione.online/policies/shipping-policy.html?locale=it" target="_blank">Shipping policy</a></li>
        <li class="float-left pr-4 hover:text-gray-800 pt-2 md:pt-0"><a href="https://corsiformazione.online/policies/privacy-policy.html?locale=it" target="_blank">Informativa sulla privacy</a></li>
        <li class="float-left pr-4 hover:text-gray-800 pt-2 md:pt-0"><a href="https://corsiformazione.online/policies/terms-of-service.html?locale=it" target="_blank">Termini e condizioni del servizio</a></li>
    </ul>
</template>

<script>
export default {};
</script>